import { memo, SVGProps } from 'react';

const Size32pxFillYesVariantDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.375 0C8.58007 0 7.125 1.45508 7.125 3.25V16.25C7.125 18.0449 8.58007 19.5 10.375 19.5H20.125C21.9199 19.5 23.375 18.0449 23.375 16.25V7.1731C23.375 6.31114 23.0326 5.48449 22.4231 4.875L18.5 0.951903C17.8905 0.34241 17.0639 0 16.2019 0H10.375Z'
      fill='black'
    />
    <path
      d='M0.624999 9.75C0.624999 7.95508 2.08007 6.5 3.875 6.5V22.75H16.875C16.875 24.5449 15.4199 26 13.625 26H3.875C2.08007 26 0.624999 24.5449 0.624999 22.75V9.75Z'
      fill='black'
    />
  </svg>
);
const Memo = memo(Size32pxFillYesVariantDocument);
export { Memo as Size32pxFillYesVariantDocument };
