import { memo, SVGProps } from 'react';

const MediaIconIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M0 13C0 5.8203 5.8203 0 13 0V13H26C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z' fill='#8C2BE3' />
    <path d='M16.25 0.409424C20.8183 1.58522 24.4148 5.18175 25.5905 9.75007H16.25V0.409424Z' fill='#8C2BE3' />
  </svg>
);
const Memo = memo(MediaIconIcon2);
export { Memo as MediaIconIcon2 };
