import { memo } from "react";
import type { FC, ReactNode } from "react";

import resets from "../../_resets.module.css";
import { MediaIcon_Size24pxFillNoVarian as MediaIconSize24pxFillNoVarian } from "../MediaIcon_Size24pxFillNoVarian/MediaIcon_Size24pxFillNoVarian";
import classes from "./ButtonMasterMedium.module.css";
import { IconLeftIcon } from "./IconLeftIcon";
import { IconRightIcon } from "./IconRightIcon";

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
    icon2?: ReactNode;
  };
  hide?: {
    iconLeft?: boolean;
    iconRight?: boolean;
  };
  text?: {
    label?: ReactNode;
  };
}
/* @figmaId 898:71045 */
export const ButtonMasterMedium: FC<Props> = memo(function ButtonMasterMedium(
  props = {}
) {
  return (
    <div
      className={`${resets.storybrainResets} ${props.classes?.root || ""} ${
        props.className || ""
      } ${classes.root}`}
    >
      <div className={classes.container}>
        {!props.hide?.iconLeft && (
          <MediaIconSize24pxFillNoVarian
            className={classes.iconLeft}
            swap={{
              icon: props.swap?.icon || (
                <IconLeftIcon className={classes.icon} />
              ),
            }}
          />
        )}
        {props.text?.label != null ? (
          props.text?.label
        ) : (
          <div className={classes.label}>Label</div>
        )}
        {!props.hide?.iconRight && (
          <MediaIconSize24pxFillNoVarian
            className={classes.iconRight}
            swap={{
              icon: props.swap?.icon2 || (
                <IconRightIcon className={classes.icon2} />
              ),
            }}
          />
        )}
      </div>
    </div>
  );
});
