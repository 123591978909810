import React from "react";
import heroImg from "../assets/hero.png";
import styled from "styled-components";
import classes from "./NEWPURPLEFONT/Website.module.css";
import ChromeStoreButton from "./chrome-store-button";

const Wrapper = styled.div`  
  height: min-content;
  align-items: center;
  gap: 32px;
  padding: 160px 112px;
  background-color: #1c092d;
  overflow: hidden;
  box-shadow: 0px 24px 48px -12px #1018280d;
`;

const HeroImg = styled.div`
  position: absolute;
  left: 146px;
  top: -162px;
  width: 746px;
  height: 827px;
  background-image: url(${heroImg}); //1614 × 2037
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Hero = () => (
  <Wrapper>
    <div className={classes.frame12}>
      <div className={classes.frame9}>
        <div className={classes.hireFasterAndSmarterThanYourCo}>
          Effortless co-presenting
        </div>
        <div className={classes.frame15}>
          <div className={classes.hireTheBestBeforeTheyTakeUpAno}>
            <p className={classes.labelWrapper}>
              <span className={classes.label11}>
                Co-Present allows multiple people to have control over the same
                Google Slides presentation.
              </span>
            </p>
          </div>
        </div>
        <div className={classes.frame8}>
          <ChromeStoreButton />
        </div>
      </div>
    </div>
    <div className={classes.frame151}>
      <HeroImg />
      {/* <div className={classes.frame14}>
        <div className={classes.frame142}>
          <div className={classes.frame116}>
            <div className={classes.group13}>
              <Group13Icon className={classes.icon8} />
            </div>
            <div className={classes.rectangle}></div>
            <div className={classes.frame117}>
              <div className={classes._94}>94</div>
              <div className={classes.score}>Score</div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </Wrapper>
);

export default Hero;
