import { memo, SVGProps } from 'react';

const MediaIconIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.49938 1.14544C8.27855 0.321305 7.43145 -0.167771 6.60731 0.0530538C5.78318 0.273879 5.29411 1.12098 5.51493 1.94511L5.91477 3.43734C6.1356 4.26147 6.9827 4.75054 7.80683 4.52972C8.63096 4.30889 9.12004 3.46179 8.89922 2.63766L8.49938 1.14544Z'
      fill='#8C2BE3'
    />
    <path
      d='M1.94508 5.51496C1.12095 5.29414 0.273849 5.78321 0.0530233 6.60734C-0.167802 7.43148 0.321274 8.27858 1.14541 8.49941L2.63763 8.89924C3.46176 9.12007 4.30886 8.63099 4.52969 7.80686C4.75051 6.98273 4.26144 6.13563 3.43731 5.9148L1.94508 5.51496Z'
      fill='#8C2BE3'
    />
    <path
      d='M15.5606 4.63685C16.1639 4.03355 16.1639 3.0554 15.5606 2.45209C14.9573 1.84878 13.9792 1.84878 13.3759 2.45209L12.2835 3.54447C11.6802 4.14778 11.6802 5.12593 12.2835 5.72924C12.8868 6.33254 13.865 6.33254 14.4683 5.72924L15.5606 4.63685Z'
      fill='#8C2BE3'
    />
    <path
      d='M4.63682 15.5607L5.72921 14.4683C6.33251 13.865 6.33251 12.8868 5.72921 12.2835C5.1259 11.6802 4.14775 11.6802 3.54444 12.2835L2.45206 13.3759C1.84875 13.9792 1.84875 14.9574 2.45206 15.5607C3.05537 16.164 4.03352 16.164 4.63682 15.5607Z'
      fill='#8C2BE3'
    />
    <path
      d='M9.58026 7.57212C9.00648 7.34261 8.35112 7.47713 7.91413 7.91411C7.47715 8.35109 7.34263 9.00645 7.57215 9.58024L13.7516 25.0288C13.9775 25.5937 14.5139 25.9733 15.1218 25.9986C15.7296 26.0239 16.2957 25.6901 16.5677 25.146L18.6989 20.8836L23.3627 25.5475C23.9661 26.1508 24.9442 26.1508 25.5475 25.5475C26.1508 24.9442 26.1508 23.966 25.5475 23.3627L20.8837 18.6989L25.146 16.5677C25.6901 16.2956 26.0239 15.7296 25.9987 15.1217C25.9734 14.5139 25.5937 13.9775 25.0289 13.7516L9.58026 7.57212Z'
      fill='#8C2BE3'
    />
  </svg>
);
const Memo = memo(MediaIconIcon5);
export { Memo as MediaIconIcon5 };
