import React from "react";
import { Button_SizeMediumStyleLinkIcon as ButtonSizeMediumStyleLinkIcon } from "./NEWPURPLEFONT/Button_SizeMediumStyleLinkIcon/Button_SizeMediumStyleLinkIcon";
import { MediaIconIcon6 } from "./NEWPURPLEFONT/MediaIconIcon6";
import { MediaIcon_Size32pxFillYesVaria7 as MediaIconSize32pxFillYesVaria7 } from "./NEWPURPLEFONT/MediaIcon_Size32pxFillYesVaria7/MediaIcon_Size32pxFillYesVaria7";
import { Ellipse1Icon6 } from "./NEWPURPLEFONT/Ellipse1Icon6";
import classes from "./NEWPURPLEFONT/Website.module.css";
import { MediaIcon_Size32pxFillYesVaria6 as MediaIconSize32pxFillYesVaria6 } from "./NEWPURPLEFONT/MediaIcon_Size32pxFillYesVaria6/MediaIcon_Size32pxFillYesVaria6";
import { Ellipse1Icon5 } from "./NEWPURPLEFONT/Ellipse1Icon5";
import { MediaIcon_Size32pxFillYesVaria5 as MediaIconSize32pxFillYesVaria5 } from "./NEWPURPLEFONT/MediaIcon_Size32pxFillYesVaria5/MediaIcon_Size32pxFillYesVaria5";
import { Ellipse1Icon4 } from "./NEWPURPLEFONT/Ellipse1Icon4";
import { MediaIconIcon4 } from "./NEWPURPLEFONT/MediaIconIcon4";
import { Ellipse1Icon3 } from "./NEWPURPLEFONT/Ellipse1Icon3";
import { MediaIcon_Size32pxFillYesVaria4 as MediaIconSize32pxFillYesVaria4 } from "./NEWPURPLEFONT/MediaIcon_Size32pxFillYesVaria4/MediaIcon_Size32pxFillYesVaria4";
import { MediaIconIcon3 } from "./NEWPURPLEFONT/MediaIconIcon3";
import { MediaIconIcon2 } from "./NEWPURPLEFONT/MediaIconIcon2";
import { MediaIcon_Size32pxFillYesVaria3 as MediaIconSize32pxFillYesVaria3 } from "./NEWPURPLEFONT/MediaIcon_Size32pxFillYesVaria3/MediaIcon_Size32pxFillYesVaria3";
import { Ellipse1Icon2 } from "./NEWPURPLEFONT/Ellipse1Icon2";
import { MediaIconIcon } from "./NEWPURPLEFONT/MediaIconIcon";
import { MediaIcon_Size32pxFillYesVaria2 as MediaIconSize32pxFillYesVaria2 } from "./NEWPURPLEFONT/MediaIcon_Size32pxFillYesVaria2/MediaIcon_Size32pxFillYesVaria2";
import { Ellipse1Icon } from "./NEWPURPLEFONT/Ellipse1Icon";
import { MediaIconIcon5 } from "./NEWPURPLEFONT/MediaIconIcon5";

const TopFeatures = () => (
  <div className={classes.featureBreakdown}>
    <div className={classes.title}>
      <div className={classes.byInterviewersForInterviewers}>
      Smooth Presentation Delivery
      </div>
      <div className={classes.interviewingDoesNotHaveToBeStr}>
        <div className={classes.textBlock}>Interviewing does not </div>
        <div className={classes.textBlock2}>have to be stressful</div>
      </div>
    </div>
    <div className={classes.features2}>
      <div className={classes.frame18}>
        <div className={classes.frame17}>
          <div className={classes.frame20}>
            <div className={classes.ellipse1}>
              <Ellipse1Icon className={classes.icon9} />
            </div>
            <MediaIconSize32pxFillYesVaria2
              className={classes.mediaIcon}
              swap={{
                icon: <MediaIconIcon className={classes.icon2} />,
              }}
            />
          </div>
          <div className={classes.centralizedTemplates}>
          Pitching to Investors
          </div>
          <div className={classes.avoidTemplateDuplicatesAndInCo}>
          Pitching to investors with co-founders.
          </div>
          {/* <ButtonSizeMediumStyleLinkIcon
            text={{
              label: <div className={classes.label3}>Learn more</div>,
            }}
          /> */}
        </div>
        <div className={classes.frame202}>
          <div className={classes.frame21}>
            <div className={classes.ellipse12}>
              <Ellipse1Icon2 className={classes.icon10} />
            </div>
            <MediaIconSize32pxFillYesVaria3
              className={classes.mediaIcon2}
              swap={{
                icon: <MediaIconIcon2 className={classes.icon3} />,
              }}
            />
          </div>
          <div className={classes.interviewerScore}>Town Hall Meetings</div>
          <div className={classes.takeTheHardWorkOutOfScoringCan}>
            <div className={classes.textBlock3}>
            Presenting at online Town Hall meetings with multiple presenters
            </div>
            <div className={classes.textBlock4}>
              Let us do the heavy-lifting for you.
            </div>
          </div>
          {/* <ButtonSizeMediumStyleLinkIcon
            text={{
              label: <div className={classes.label4}>Learn more</div>,
            }}
          /> */}
        </div>
        <div className={classes.frame19}>
          <div className={classes.frame203}>
            <div className={classes.ellipse13}>
              <Ellipse1Icon3 className={classes.icon11} />
            </div>
            <MediaIconSize32pxFillYesVaria4
              className={classes.mediaIcon3}
              swap={{
                icon: <MediaIconIcon3 className={classes.icon4} />,
              }}
            />
          </div>
          <div className={classes.automatedReporting}>Automated reporting</div>
          <div className={classes.summarizingInterviewNotesCanBe}>
            <div className={classes.textBlock5}>
              Summarizing interview notes can be a nightmare.
            </div>
            <div className={classes.textBlock6}>
              One click reporting is now here 👋
            </div>
          </div>
          <ButtonSizeMediumStyleLinkIcon
            text={{
              label: <div className={classes.label5}>Learn more</div>,
            }}
          />
        </div>
      </div>
      <div className={classes.frame192}>
        <div className={classes.frame193}>
          <div className={classes.frame212}>
            <div className={classes.ellipse14}>
              <Ellipse1Icon4 className={classes.icon12} />
            </div>
            <MediaIconSize32pxFillYesVaria5
              className={classes.mediaIcon4}
              swap={{
                icon: <MediaIconIcon4 className={classes.icon5} />,
              }}
            />
          </div>
          <div className={classes.organizedWorkspace}>Organized workspace</div>
          <div className={classes.keepAllYourInterviewingResourc}>
            Keep all your interviewing resources and candidate information in
            one place.
          </div>
          <ButtonSizeMediumStyleLinkIcon
            text={{
              label: <div className={classes.label6}>Learn more</div>,
            }}
          />
        </div>
        <div className={classes.frame204}>
          <div className={classes.frame205}>
            <div className={classes.ellipse15}>
              <Ellipse1Icon5 className={classes.icon13} />
            </div>
            <MediaIconSize32pxFillYesVaria6
              className={classes.mediaIcon5}
              swap={{
                icon: <MediaIconIcon5 className={classes.icon6} />,
              }}
            />
          </div>
          <div className={classes.liveCollaboration}>Live collaboration</div>
          <div className={classes.streamlineYourWorkflowWithYour}>
            Streamline your workflow with your interview partner with live
            collaboration in our workspace.
          </div>
          <ButtonSizeMediumStyleLinkIcon
            text={{
              label: <div className={classes.label7}>Learn more</div>,
            }}
          />
        </div>
        <div className={classes.frame182}>
          <div className={classes.frame213}>
            <div className={classes.ellipse16}>
              <Ellipse1Icon6 className={classes.icon14} />
            </div>
            <MediaIconSize32pxFillYesVaria7
              className={classes.mediaIcon6}
              swap={{
                icon: <MediaIconIcon6 className={classes.icon7} />,
              }}
            />
          </div>
          <div className={classes._100InterviewTemplates}>
            100+ interview templates
          </div>
          <div className={classes.donTHaveTimeToFormInterviewQue}>
            <div className={classes.textBlock7}>
              Don’t have time to form interview questions?
            </div>
            <div className={classes.textBlock8}>
              Access over 100 interview pre-made templates.
            </div>
          </div>
          <ButtonSizeMediumStyleLinkIcon
            text={{
              label: <div className={classes.label8}>Learn more</div>,
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default TopFeatures;
