import { memo, SVGProps } from 'react';

const Size32pxFillYesVariantDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.249999 3.25C0.249999 1.45508 1.70507 0 3.5 0H10.9519C11.8139 0 12.6405 0.34241 13.25 0.951903L18.7981 6.5C19.4076 7.10949 19.75 7.93614 19.75 8.7981V22.75C19.75 24.5449 18.2949 26 16.5 26H3.5C1.70507 26 0.249999 24.5449 0.249999 22.75V3.25ZM3.5 13C3.5 12.1025 4.22754 11.375 5.125 11.375H14.875C15.7725 11.375 16.5 12.1025 16.5 13C16.5 13.8975 15.7725 14.625 14.875 14.625H5.125C4.22754 14.625 3.5 13.8975 3.5 13ZM5.125 17.875C4.22754 17.875 3.5 18.6025 3.5 19.5C3.5 20.3975 4.22754 21.125 5.125 21.125H14.875C15.7725 21.125 16.5 20.3975 16.5 19.5C16.5 18.6025 15.7725 17.875 14.875 17.875H5.125Z'
      fill='black'
    />
  </svg>
);
const Memo = memo(Size32pxFillYesVariantDocument);
export { Memo as Size32pxFillYesVariantDocument };
