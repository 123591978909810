import { memo, SVGProps } from 'react';

const Size24pxFillNoVariantHeartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.31802 1.31802C-0.43934 3.07538 -0.43934 5.92462 1.31802 7.68198L9.00005 15.364L16.682 7.68198C18.4393 5.92462 18.4393 3.07538 16.682 1.31802C14.9246 -0.43934 12.0754 -0.43934 10.318 1.31802L9.00005 2.63609L7.68198 1.31802C5.92462 -0.43934 3.07538 -0.43934 1.31802 1.31802Z'
      stroke='black'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const Memo = memo(Size24pxFillNoVariantHeartIcon);
export { Memo as Size24pxFillNoVariantHeartIcon };
