import { memo, SVGProps } from 'react';

const IconLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.59835 1.26502C0.133883 2.72948 0.133883 5.10385 1.59835 6.56832L8.00004 12.97L14.4017 6.56832C15.8661 5.10385 15.8661 2.72948 14.4016 1.26502C12.9372 -0.19945 10.5628 -0.19945 9.09835 1.26502L8.00004 2.36341L6.90165 1.26502C5.43718 -0.19945 3.06282 -0.19945 1.59835 1.26502Z'
      stroke='#7022B6'
      strokeWidth={1.66667}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const Memo = memo(IconLeftIcon);
export { Memo as IconLeftIcon };
