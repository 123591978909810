import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { ButtonMasterMedium } from '../ButtonMasterMedium/ButtonMasterMedium';
import classes from './ButtonStyleWithLabelLinkMedium.module.css';
import { IconLeftIcon } from './IconLeftIcon';
import { IconRightIcon } from './IconRightIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  hide?: {
    iconLeft?: boolean;
    iconRight?: boolean;
  };
  text?: {
    label?: ReactNode;
  };
}
/* @figmaId 898:71062 */
export const ButtonStyleWithLabelLinkMedium: FC<Props> = memo(function ButtonStyleWithLabelLinkMedium(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <ButtonMasterMedium
        className={classes.ButtonMasterMedium}
        swap={{
          icon: <IconLeftIcon className={classes.icon} />,
          icon2: <IconRightIcon className={classes.icon2} />,
        }}
        hide={{
          iconLeft: props.hide?.iconLeft,
          iconRight: props.hide?.iconRight,
        }}
        text={{
          label: props.text?.label || <div className={classes.label}>Label</div>,
        }}
      />
    </div>
  );
});
