import { memo, SVGProps } from 'react';

const Size32pxFillYesVariantDesktopC = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.86666 3.46667C0.86666 1.55208 2.41874 0 4.33333 0H21.6667C23.5813 0 25.1333 1.55208 25.1333 3.46667V17.3333C25.1333 19.2479 23.5813 20.8 21.6667 20.8H17.8201L18.0319 21.6472L19.4257 23.041C19.9214 23.5367 20.0697 24.2823 19.8014 24.93C19.5332 25.5777 18.9011 26 18.2001 26H7.80005C7.09898 26 6.46694 25.5777 6.19866 24.93C5.93037 24.2823 6.07867 23.5367 6.5744 23.041L7.96824 21.6472L8.18003 20.8H4.33333C2.41874 20.8 0.86666 19.2479 0.86666 17.3333V3.46667ZM10.8702 15.6C10.8447 15.5994 10.8193 15.5994 10.7939 15.6H4.33333V3.46667H21.6667V15.6H15.2062C15.1808 15.5994 15.1554 15.5994 15.1299 15.6H10.8702Z'
      fill='black'
    />
  </svg>
);
const Memo = memo(Size32pxFillYesVariantDesktopC);
export { Memo as Size32pxFillYesVariantDesktopC };
