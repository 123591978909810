import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { ButtonStyleWithLabelLinkMedium } from '../ButtonStyleWithLabelLinkMedium/ButtonStyleWithLabelLinkMedium';
import classes from './Button_SizeMediumStyleLinkIcon.module.css';

interface Props {
  className?: string;
  hide?: {
    iconLeft?: boolean;
    iconRight?: boolean;
  };
  text?: {
    label?: ReactNode;
  };
}
/* @figmaId 898:71597 */
export const Button_SizeMediumStyleLinkIcon: FC<Props> = memo(function Button_SizeMediumStyleLinkIcon(props = {}) {
  return (
    <button className={`${resets.storybrainResets} ${classes.root}`}>
      <ButtonStyleWithLabelLinkMedium
        className={classes.ButtonStyleWithLabelLinkMedium}
        hide={{
          iconLeft: true,
          iconRight: true,
        }}
        text={{
          label: props.text?.label,
        }}
      />
    </button>
  );
});
