import React from "react";

export const MadeBy = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <p>
        Made by{" "}
        <a href="https://huskystudio.com.au" target="_blank" rel="noreferrer">
          Husky Studio
        </a>
      </p>
    </div>
  );
};
