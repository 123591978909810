import { memo } from "react";
import type { FC } from "react";
import resets from "../_resets.module.css";
//import classes from "./Website.module.css";
import Header from "../header";
import Hero from "../hero";
import TopFeatures from "../top-features";
// import Footer from "../footer";
// import CTA from "../cta";
// import UseCases from "../use-cases";
// import Testimonials from "../testimonials";
import styled from "styled-components";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f9fafb;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
`;

export const Website: FC = memo(function Website() {
  return (
    <Root className={`${resets.storybrainResets}`}>
      <Hero />
      <Header />
      <Content>
        <TopFeatures />
        {/* <UseCases />
        <div className={classes.line72}></div>
        <Testimonials /> */}
      </Content>
      {/* <div className={classes.footer}>
        <CTA />
        <div className={classes.line73}></div>
        <Footer />
      </div> */}
    </Root>
  );
});
