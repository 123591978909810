import { memo, SVGProps } from 'react';

const MediaIconIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 3.5C0 1.70507 1.45507 0.249999 3.25 0.249999H9.75L13 3.5H19.5C21.2949 3.5 22.75 4.95507 22.75 6.75V8.375H9.75C7.05761 8.375 4.875 10.5576 4.875 13.25V15.6875C4.875 17.0337 3.78369 18.125 2.4375 18.125C1.09131 18.125 0 17.0337 0 15.6875V3.5Z'
      fill='#8C2BE3'
    />
    <path
      d='M6.5 13.25C6.5 11.4551 7.95507 10 9.75 10H22.75C24.5449 10 26 11.4551 26 13.25V16.5C26 18.2949 24.5449 19.75 22.75 19.75H0H3.25C5.04493 19.75 6.5 18.2949 6.5 16.5V13.25Z'
      fill='#8C2BE3'
    />
  </svg>
);
const Memo = memo(MediaIconIcon6);
export { Memo as MediaIconIcon6 };
